import React from "react"
import Nav from "../../components/nav.js"
import Retail from "../ingredients/@retail.js"

export default function Retail_Nav() {
  return (
    <Nav>
      <Retail />
    </Nav>
  )
}